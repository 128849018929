/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .combobox-main-div {
    margin: 0;
    text-align: center;
    width: 90vw;
    color: #454545;
    border: 1px solid;
    border-color: #99C8FD;
    border-radius: 8px;
    padding: 1rem 1rem 1rem 1rem;
  }
  .combobox-main-div:focus {
    outline: none;
  }
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}